@import '@/assets/css/index.scss';

.contentWrapper {
    max-width: 900px;
    margin: 0 auto;
    min-height: 100vh;
    align-items: center;
    row-gap: $content-padding--large;

    // collapse for small devices
    @media screen and (max-height: 660px) {
        row-gap: $content-padding--medium;
    }
}

.contentBoxWrapper {
    padding: $content-padding;

    @include desktop() {
        padding: $content-padding-desktop;
    }
}

.iconWrapper {
    width: 100%;
    height: 240px;

    // collapse for small devices
    @media screen and (max-height: 660px) {
        height: 140px;
    }

    @include desktop() {
        margin-top: 20px;
        margin-bottom: 32px;
    }
}

.headline {
    font-size: 24px;
}

.text {
    line-height: 20px;
    font-size: 16px;

    @include desktop() {
        font-size: 20px;
        line-height: 40px;
    }
}

.textWrapper {
    row-gap: $content-padding--large;
    padding: 0 $content-padding--large;
    text-align: center;

    @include desktop() {
        margin-bottom: 76px;
    }
}

.buttonWrapper {
    width: 100%;
    flex-direction: column;
    @include flex-gap($content-padding);

    @include desktop {
        max-width: 335px;
        @include flex-gap($content-padding-desktop);
    }
}
